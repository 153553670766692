<template>
  <v-list
    class="dispute-stats"
    v-if="account"
  >
    <v-list-item v-if="disputes.openAmount">
      <v-list-item-title v-text="formatCurrency(disputes.openAmount, account.currency)" />
      <v-list-item-subtitle v-text="$t('t.Amount')" />
    </v-list-item>
    <v-list-item v-if="disputes.balance">
      <v-list-item-title v-text="formatCurrency(disputes.balance, account.currency)" />
      <v-list-item-subtitle v-text="$t('t.Balance')" />
    </v-list-item>
    <v-list-item v-if="disputes.approvedRate">
      <v-list-item-title v-text="disputes.approvedRate + ' %'" />
      <v-list-item-subtitle v-text="$t('t.Approved')" />
    </v-list-item>
    <v-list-item v-if="disputes.rejectedRate">
      <v-list-item-title v-text="disputes.rejectedRate + ' %'" />
      <v-list-item-subtitle v-text="$t('t.Rejected')" />
    </v-list-item>
    <v-list-item v-if="disputes.durationAvg">
      <v-list-item-title v-text="$tc('t.DaysX', disputes.durationAvg)" />
      <v-list-item-subtitle v-text="$t('t.ResolutionTime')" />
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  computed: {
    disputes () {
      return this.account?.disputes || {}
    }
  },
  props: {
    account: Object
  }
}
</script>
<style lang="stylus" scoped>
.v-list
  display flex
  flex-direction row

.v-list-item
  display flex
  flex-direction column
  min-height auto
</style>
